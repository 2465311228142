<template>


  <CRow>
 
   
    <CCol style="margin-bottom:10px;">

     <CButton title="Generar referencia"  style="width: 500px; float:right;" @click="generar_referencia()" block color="facebook">
        Generar nueva referencia de pago de inscripción cuatrimestral
      </CButton>

     <!-- <CButton style="width: 850px; float:right;" @click="generar_referencia()" block color="facebook">
      Generar nueva referencia de pago de Aportacion anual para Seguro de Gastos Médicos Contra Accidentes para los Alumnos
      </CButton> -->

    </CCol>   
    
    <CCol col="12" md="12">
            <CTableWrapper
                    :items="getShuffledUsersData()"
                    small
                    caption="Referencias Generadas para el pago de Inscripción"
            />
          </CCol>

          <!-- referencias seguro -->
   <CCol style="margin-bottom:10px;">
     <CButton  title="Generar referencia" style="width: 800px; float:right;" @click="generar_referencia_seguro()" block color="facebook">
      Generar nueva referencia de pago de Aportación anual para Seguro de Gastos Médicos Contra Accidentes para los Alumnos
      </CButton>
    </CCol>            
       <CCol col="12" md="12">
            <CTableWrapper2
                    :items="getShuffledUsersData2()"
                    small
                    caption="Referencias Generadas para el pago de Aportación anual para Seguro de Gastos Médicos Contra Accidentes para los Alumnos" />
          </CCol>



<CCol sm="3">

</CCol>
    <CCol sm="6">
      <CCard>
        <CCardHeader>
          <strong>Nota Importante</strong>
        </CCardHeader>

        <CCardBody>
 
          <CForm inline>
            <CRow>
              <CCol sm="12" style="text-align: left">
                 <!-- <ol>asa</ol> -->
                 <CAlert color="info">


               <li>
                 <b> Una vez realizado tus pagos, tardarán hasta 24 hrs (hábiles) en acreditarse.
                  </b>
                </li>
                <li>
                  <b>Una vez validados tus pagos, el sistema se habilitará el módulo para continuar el proceso de inscripción.
                  </b>
                </li>
                   <li>
                  <b>En caso de que sus referencias bancarias caduquen, regrese al boton de generar una nueva referencia de pago.
                  </b>
                </li>
                   <li>
                  <b>Recuerda guardar copia de tus pagos efectuados y las referencias bancarias para cualquier duda o aclaración.
                  </b>
                </li>
                
                <br/>
                </CAlert>
              </CCol>
            </CRow>

          </CForm>
        </CCardBody>
        <CCardFooter>
        </CCardFooter>
      </CCard>
    </CCol>
  </CRow>


</template>

<script>
  import CTableWrapper from './Table_referenciaInscripcion.vue'
  import CTableWrapper2 from './TableReferenciasSeguro.vue';
export default {
  // name: 'Alerts',
  name:'referenciaInscripcion',
  components: { 
    CTableWrapper,
    CTableWrapper2
       },
  data () {
    return {
      id:localStorage.getItem('id'),
      usersData: [ { referencia: '_', fechaCaducidad: '_', Estatus: 'Pendiente de pago'}],
      usersData2: [ { referencia: '_', fechaCaducidad: '_', Estatus: 'Pendiente de pago'}],
    }
  },
  created() {
    this.get_referencias();
    this.get_referencias_seguro();

  },
  methods: {

    get_referencias(){
      this.$http.get("referenciasCandidatoAceptadoInscripcion/" + this.id).then(
              response => {
     
                this.usersData = response.data.referenciasInscripcion;
                if(this.usersData[0].pagado == 0){
                  this.usersData[0].Estatus = "Pendiente de pago"
                 }
                if(this.usersData[0].pagado == 1){
                  this.usersData[0].Estatus = "Pagado"
                  // this.usersData[0].Estatus = '<span class="badge rounded-pill text-bg-success">Pagado</span>';
                 }
          
              },
              error => {
                this.StepError(error);
              }
      );
    },

    get_referencias_seguro(){
      this.$http.get("referenciasCandidatoAceptadoSeguro/" + this.id).then(
              response => {
                this.usersData2 = response.data.referenciasSeguro;
                if(this.usersData2[0].pagado == 0){
                  this.usersData2[0].Estatus = "Pendiente de pago"
                 }
                if(this.usersData2[0].pagado == 1){
                  this.usersData2[0].Estatus = "Pagado"
                 }
              },
              error => {
                this.StepError(error);
              }
        );
    },


    getShuffledUsersData () {
      return this.shuffleArray(this.usersData.slice(0))
    },

    getShuffledUsersData2 () {
      return this.shuffleArray(this.usersData2.slice(0))
    },

    shuffleArray (array) {
      for (let i = array.length - 1; i > 0; i--) {
        let j = Math.floor(Math.random() * (i + 1))
        let temp = array[i]
        array[i] = array[j]
        array[j] = temp
      }
      return array
    },

       alert_notify(){
        return alert("se ha generado la referencia correctamente!")

      },

    generar_referencia(){

        let text ="Estas seguro que quieres generar nueva referencia de pago de Inscripcion!";

            if(confirm(text) == true){
                    this.$http.post('/generarreferenciaadmisionCandidatosAceptados', { 
                         id: this.id,  
                         }).then(response => {
                                
                            if(response.data.referenciasInscripcion ==1){
                                         this.alert_notify();
                                          this.get_referencias();
                                          window.open('https://cdn.upbicentenario.edu.mx/api/pdf_pago_inscripcion_nuevo_ingreso/' + this.id);
                                           // location.reload();
                            }
                    })          
                                        
                 }else {
                  text = "Operación Cancelada!";
                 } 
     },

    generar_referencia_seguro(){

        let text ="Estas seguro que quieres generar nueva referencia de pago de seguro!";

            if(confirm(text) == true){
                    this.$http.post('/generarreferenciaadmisionCandidatosAceptadosSeguro', { 
                         id: this.id,  
                         }).then(response => {
                                
                            if(response.data.referenciasSeguro ==1){
                                         this.alert_notify();
                                          this.get_referencias_seguro();
                                           window.open('https://cdn.upbicentenario.edu.mx/api/pdf_pago_seguro_nuevo_ingreso/' + this.id);
                               }
                    })          
                                        
                 }else {
                  text = "Operación Cancelada!";
                 } 
     },


  }
}
</script>
